footer {
	.footer-menu {
		li {
			a {
				@apply text-caption;
				@apply mr-2;
				@apply relative;
				@apply flex;
				@apply justify-center;

				&::after {
					content: "";
					@apply inline-block;
					@apply w-footer;
					@apply h-4;
					@apply bg-white;
					@apply ml-2;
				}
			}

			&:last-child {
				a {
					@apply mr-0;

					&::after {
						@apply hidden;
					}
				}
			}
		}
	}
}
