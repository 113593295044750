
@font-face {
	font-family: "sora-bold";
	font-display: swap;
	src: url("/src/fonts/sora-bold.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "barlow-regular";
	font-display: swap;
	src: url("/src/fonts/barlow-regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

