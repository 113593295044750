.fui-form-container {
	@apply font-barlow-regular;
	@apply text-contact-form;
	@apply text-body-light;

	.fui-alert {
		@apply font-barlow-regular;
		@apply text-contact-form;
	}

	.fui-input {
		@apply bg-background-dark;
	}

	#fields-kontaktformular-message {
		@apply h-36;
	}

	#fields-kontaktformular-mobile-message {
		@apply h-36;
	}

	.fui-checkbox-label {
		@apply text-body-light;

		&::before {
			@bg-transparent;
		}
	}

	.fui-checkbox {
		label {
			&::before {
				border-radius: 2px;
				@apply bg-transparent;
				border: 2px solid;
			}

			a {
				@apply underline;
			}
		}
	}

	.send-button-contactform {
		.fui-submit {
			@apply flex;
			@apply justify-center;
			@apply items-center;
			@apply font-barlow-regular;
			@apply text-body-mobile;
			@apply text-background-dark;
			@apply bg-action;
			@apply rounded;
			@apply no-underline;
			@apply px-5;
			@apply border-none;

			&:hover,
			&.active {
				@apply cursor-pointer;
				@apply bg-action-shade-2;
			}
		}
	}
}
