
// global swiper flickering fix
.swiper-slide {
	/* stylelint-disable */
	-webkit-transform: translate3d(0, 0, 0);
	/* stylelint-enable */
}

// for media-queries use:
// 		@screen md {
// 			...
// 		}


.grid-section {
	.grid-icon {
		svg {
			@apply block;
			@apply ml-0;
			
			@screen md {
				@apply block;
				@apply mr-auto;
				@apply ml-auto;
			}
		
		}
	}
}

.image-card-section {
	.separator {
		color: rgb(217, 242, 250);
		border: 1px solid #69f;
	}
}

.rich-textfield {
	p {
		@apply font-barlow-regular;
		@apply text-body;
		@apply leading-body;
	}
}
