.rich-textfield {
	p {
		@apply text-body-mobile;
		@apply leading-body-mobile;
		@apply mb-4;

		@screen md {
			@apply text-body;
			@apply leading-body;
		}

		strong {
			@apply font-sora-bold;
		}

		a {
			@apply underline;
		}
	}

	h4 {
		@apply text-h4-mobile;
		@apply font-sora-bold;
		@apply mb-8;
		@apply mt-8;

		@screen md {
			@apply text-h4;
		}
	}

	ul {
		@apply pl-8;
	}

	li {
		@apply list-disc;
	}
}
